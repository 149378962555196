.chooseProvider-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 73px 0 32px 0;

    &__logo {
      margin-bottom: 64px;
      svg {
        height: 60px;
        width: 150px;
        fill: white;
      }
    }
    &__title {
        font-size: 40px;
        font-weight: 800;
        line-height: 47px;
        margin-bottom: 16px;
        color: #ffffff;
      }
    &__description {
        text-align: left;
        font-size: 24px;
        line-height: 37px;
        margin-bottom: 48px;
        max-width: 733px;
        color:#ffffff;
    }
    &__mvpdLogoContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #ffffff;
      width: 360px;
      height: 100px;
      margin-bottom: 24px;
    }
    &__mvpdLogo {
      width: 160px;
      height: auto;
      cursor: pointer;
    }
    &__language {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        border: 1px solid transparent;
        border-radius: 0.25rem;
        background: none;
        color: #ffffff; 
        cursor: pointer;
        text-decoration: underline;
      }

      @media screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100%;
        margin: 80px 0 32px 0;
        .chooseProvider-screen {
            &__logo {
              margin-bottom: 32px;
              svg {
                height: 40px;
                width: 106.7px;
              }
            }
            &__title {
                font-size: 24px;
                line-height: 30px;
                margin-bottom: 16px;
            }
            &__description {
                font-size: 16px;
                line-height: 24px;
                margin: 0px 26px 48px 26px;
                // min-width: calc(100vw - 54px);
                min-width: 361px;
            }
            &__language {
                font-size: 0.8em;
              }
        }
      }
}