.signin-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 73px 0 32px 0;

    &__logo {
        svg {
            height: 60px;
            width: 150px;
        }
        margin-bottom: 64px;
    }
    &__title {
        font-size: 40px;
        font-weight: 800;
        line-height: 47px;
        margin-bottom: 16px;
        color: #ffffff;
        max-width: 733px;
      }
    &__description {
        text-align: left;
        font-size: 24px;
        line-height: 37px;
        margin-bottom: 60px;
        max-width: 733px;
        color: #ffffff;
    }
    &__signInWithSpectrumButton {
        width: 272px;
        height: 44px;
        margin-bottom: 24px;
        font-weight: 500;
        background-color: #3171ca;
        color: #ffffff;
        border: 1px solid #3171ca;
        border-radius: 5px;
        cursor: pointer;
    }
    &__signInWithSpectrumButton:focus {
        outline: none;
    }
    &__signInWithAnotherProviderButton {
        width: 272px;
        height: 44px;
        font-weight: 500;
        background-color: #1f232a;
        color: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 5px;
        cursor: pointer;
    }
    &__signInWithAnotherProviderButton:focus {
        outline: none;
    }
    &__language {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        border: 1px solid transparent;
        border-radius: 0.25rem;
        background: none;
        color: #ffffff;
        cursor: pointer;
        text-decoration: underline;
      }

    @media screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100%;
        margin: 80px 0 32px 0;
        .signin-screen {
            &__logo {
                svg {
                    height: 40px;
                    width: 106.7px;
                }
                margin-bottom: 32px;
            }
            &__title {
                font-size: 24px;
                line-height: 30px;
                margin-bottom: 16px;
            }
            &__description {
                font-size: 16px;
                line-height: 24px;
                margin: 0 26px 80px 26px;
                min-width: calc(100vw - 54px);
            }
            &__signInWithSpectrumButton {
                height: 44px;
                width: 382px;
                margin: 0 16px 24px 16px;
              }
            &__signInWithAnotherProviderButton {
                height: 44px;
                width: 382px;
                margin: 0 16px;
            }
            &__language {
                font-size: 0.8em;
              }
        }
    }
}