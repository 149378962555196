.success-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  margin: 73px 0 32px 0;
  &__logo {
    svg {
      height: 60px;
      width: 150px;
    }
    margin-bottom: 64px;
  }
  &__title {
    font-size: 40px;
    font-weight: 800;
    line-height: 46px;
    margin-bottom: 16px;
  }
  &__description {
    width: 733px;
    font-size: 24px;
    font-weight: 400;
    line-height: 37px;
    text-align: left;
  }
  &__language {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid white;
    border-radius: 0.25rem;
    background: none;
    color: white;
    cursor: pointer;
    width: 100px;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 0 32px 0;
    &__logo {
      svg {
        height: 40px;
        width: 106.7px;
      }
      margin-bottom: 32px;
    }
    &__title {
      font-size: 24px;
      line-height: 30px;
      margin: 0 26px 16px 26px;
    }
    &__description {
      font-size: 16px;
      line-height: 24px;
      margin: 0 26px 16px 26px;
      width: calc(100% - 54px);
    }
  }
}
